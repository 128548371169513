<template>
    <div class="container">
        <div class="talk-main" ref="messageContainer">
            <!-- 聊天内容区域 -->
            <div class="chat-window">
                <div class="messages">
                    <!-- 对话消息 -->
                    <div v-for="message in messages" :key="message.id"
                        :class="{ 'my-message': message.isMine, 'other-message': !message.isMine }"
                        @mouseenter="showMenu = message.id" @mouseleave="showMenu = null">
                        <span style="white-space: pre-wrap;">{{ message.text }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="talk-footer" inset>
            <v-text-field v-model="newMessage" label="输入问题，按回车键发送" filled clearable @keyup.enter="sendMessage"
                ref="messageInput" dense flat hide-details rounded solo class="custom-text-field"></v-text-field>
        </div>
    </div>
</template>

<script>
import service from '@/utils/request';

export default {
    name: "Talk",
    data() {
        return {
            newMessage: '',
            messages: [],
            isSending: false,
            showMenu: null,
        };
    },
    methods: {
        scrollToEnd() {
            let container = this.$refs.messageContainer;
            this.$nextTick(() => {
                container.scrollTop = container.scrollHeight;
            });
        },
        sendMessage() {
            if (this.isSending) {
                return;
            }
            this.isSending = true;

            const newMessageId = this.messages.length + 1;
            this.addMessage(this.newMessage, true, newMessageId);
            this.addMessage("机器人正在思考，请耐心等待……", false, newMessageId + 1);
            this.scrollToEnd();

            const formData = new FormData();
            formData.append('question', this.newMessage);
            const token = localStorage.getItem('token');
            formData.append('token', token);

            service.post("/api/wiki/talk", formData).then(response => {
                const data = response.data;
                this.messages[this.messages.length - 2].id = data.question.id;
                this.messages[this.messages.length - 1].text = data.answer.content;
                this.messages[this.messages.length - 1].id = data.answer.id;

                this.scrollToEnd();
                this.isSending = false;
            });

            this.newMessage = '';
        },
        addMessage(text, isMine, id) {
            this.messages.push({
                id: id,
                text: text,
                isMine: isMine
            });
            this.scrollToEnd();
        },
        loadChatHistory() {
            const token = localStorage.getItem('token');
            service.get(`/api/wiki/talklog?token=${token}`).then(response => {
                this.messages = response.data.map((msg, index) => ({
                    id: msg.id,
                    text: msg.content,
                    isMine: msg.role === 'question'
                }));
                this.scrollToEnd();
            });
        }
    },
    mounted() {
        this.$refs.messageInput.focus();
        this.loadChatHistory();
    },
    created() {
    },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}

.talk-main {
  flex-grow: 1;
  overflow-y: auto;
  height: 80vh;
}

.chat-window {
  padding: 15px;
}

.messages {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.my-message,
.other-message {
  max-width: 70%;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-message {
  background-color: #DCF8C6;
  align-self: flex-end;
}

.other-message {
  background-color: #DDDDDD;
  align-self: flex-start;
}

.custom-text-field >>> .v-input__slot {
  background-color: #DCF8C6 !important;
}

.talk-footer {
  padding: 10px;
}
</style>
